'use strict';

require('./src/assets/scss/init.scss');

// the code highlighting theme this template provide
// require('./static/css/prismjs/theme.min.css');

// use tomorrownight theme
require('./static/css/prismjs/tomorrownight.min.css');

// enhance UX for mobile device
require('./static/css/prismjs/enhance.css');